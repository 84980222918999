import { Skeleton } from "antd";
import { observer, useLocalObservable } from "mobx-react-lite";
import React, {useEffect } from "react";
import { useLocation } from "react-router";
import { getAllPages } from "routers/router";
import useHttpService from "../hooks/useHttpService";
import useGlobalData, { User } from "../hooks/useGlobalData";
import { runInAction } from "mobx";
import { isWechat } from "helper/functions";


const PagePreCheck = ({children, auth}:{children: JSX.Element, auth: boolean}) => {
    let globalState = useGlobalData();
    const location = useLocation();
    const {http} = useHttpService();
    const state = useLocalObservable(()=>({
        *checkCurrentUser(){
            const currentUserUrl = isWechat() ? '/wechat/current_user' : '/account/current_user';
            const u = yield http.get(currentUserUrl);
            if (u) {
                runInAction(()=>{
                    globalState.user = u as User;
                })
            }
        }
    }));

    useEffect(()=>{
        if (auth && !globalState.user) {
            state.checkCurrentUser();
        }
    }, [auth, globalState.user, state]);

    useEffect(()=>{
        const path = location.pathname;
        const page = getAllPages().find(p => p.path === path);
        if (page) {
          window.document.title = page.title;
        }
      }, [location])


    if (!auth || globalState.user) {
        return children;
    }
    return <Skeleton active />;
}

export default observer(PagePreCheck);