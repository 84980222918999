import React, { Suspense } from 'react';
import zhCN from 'antd/es/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
import { ConfigProvider, Skeleton } from 'antd';
import PagePreCheck from './component/PagePreCheck';
import { Route, Routes } from 'react-router';
import { routers } from './routers/router';
import { BrowserRouter } from 'react-router-dom';
import PageRoute from './routers/PageRoute';
import './App.css';


const renderPage = (Child: any, auth: boolean) => {

  // 包裹一层PagePreCheck用于检查登录状态、更改页面标题等前期动作
  return (
    <PagePreCheck auth={auth}>
      <Suspense fallback={<Skeleton active />}>
        <Child />
      </Suspense>
    </PagePreCheck>
  )
}

const genRoute: ((list : PageRoute[]) => React.ReactNode) = list => {
  return list.map(l => {
    let Layout = null;
    let layoutProps = null;
    if (l.layout && "layout" in l.layout) {
      Layout = l.layout.layout;
      layoutProps = l.layout.props
    } else if (l.layout) {
      Layout = l.layout;
    }
    //const Layout = typeof(l.layout) == 'PropsLayout' ? React.lazy(() => import(`${l.layout}`)) : l.layout;
    if (l.children && l.children.length) {
      return (
        <Route key={l.key} path={l.path} element={Layout ? <Layout {...layoutProps}/> : undefined}>
          {genRoute(l.children)}
        </Route>
      );
    }
    const Child = typeof(l.component) == 'string' ? React.lazy(() => import(`${l.component}`)) : l.component;
    if (Layout) {
      return (
        <Route key={l.key} element={<Layout {...layoutProps} />}>
          <Route path={l.path} element={renderPage(Child, l.auth !== false)}/>
        </Route>
      );
    }
    return <Route key={l.key} path={l.path}  element={renderPage(Child, l.auth !== false)} />;
  });
}

const App : React.FC = () => {
    const routeElectments = genRoute(routers);
    dayjs.locale('zh-cn');

    return (
      <BrowserRouter>
        <ConfigProvider locale={zhCN}  theme={{ token: { colorPrimary: '#1d39c4', colorText: 'black'}}}>
          <Routes>{routeElectments}</Routes>
        </ConfigProvider>
      </BrowserRouter>
    )
}

export default App;