import dayjs from 'dayjs';



const dateTimeFormat = (dateTimeString, format="YYYY/M/D HH:mm") => {
  if (!dateTimeString) {
    return dateTimeString;
  }
  return dayjs(dateTimeString).format(format);
}

const convertMomentFieldToString = (object, format="YYYY-MM-DD HH:mm:ss") => {
  if (!object) {
    return object;
  }
  const convertted = {...object};
  Object.keys(object).filter(k => dayjs.isDayjs(object[k])).forEach(k => convertted[k] = object[k].format(format));
  return convertted;
}

const isWechat = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') > -1;
  //return true;
}

function debounce(fn, wait) {
  let callback = fn;    
  let timerId = null;

  function debounced() {
      // 保存作用域
      let context = this;
      // 保存参数，例如 event 对象
      let args = arguments;        

      clearTimeout(timerId);        
      timerId = setTimeout(function() {            
          callback.apply(context, args);
      }, wait);
  }
  
  // 返回一个闭包
  return debounced;         
}

// 空值安全
const $ = maybeNull => maybeNull || {};

const chnNumChar = { 零:0, 一:1, 二:2, 三:3, 四:4, 五:5, 六:6, 七:7, 八:8, 九:9 };
const chnNameValue = {十:{value:10, secUnit:false}, 百:{value:100, secUnit:false}, 千:{value:1000, secUnit:false}}
const chineseToNumber = (chnStr) => {
  let rtn = 0;
  let section = 0;
  let number = 0;
  let secUnit = false;
  const str = chnStr.split('');

  for(let i = 0; i < str.length; i++){
    const num = chnNumChar[str[i]];
      if(typeof num !== 'undefined'){
          number = num;
          if(i === str.length - 1){
              section += number;
          }
      } else{
        const unit = chnNameValue[str[i]].value;
          secUnit = chnNameValue[str[i]].secUnit;
          if(secUnit){
              section = (section + number) * unit;
              rtn += section;
              section = 0;
          }else{
              section += (number * unit);
          }
          number = 0;
      }
  }
  return rtn + section;
}


const hasPopedom = (globalData,keyId) => {
 
  const user = globalData.user;
  console.log("------", user);
  const { popedoms = [] } = user || {};
  const popedomKeys = popedoms && popedoms.filter(item => item.type == 'LINK').map(item => item.value);
  //  console.log("权限列表",popedomKeys,keyId)
  return popedomKeys.includes(keyId);
}


export {dateTimeFormat, $, convertMomentFieldToString, debounce, chineseToNumber, isWechat,hasPopedom}