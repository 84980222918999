import { Outlet } from "react-router";
import React from 'react';
import FooterText from "./FooterText"

const FooterLayout = () =>{
    return (
        <>
        <Outlet />
        <FooterText />
        </>
    )
}

export default FooterLayout;