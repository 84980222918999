import { makeAutoObservable } from "mobx";

type User = {
  avatar?: String,
  name?: String,
  id: Number,
  popedoms?:Array<any>
} | null

class GlobalData{
  user: User = null;

  constructor() {
    makeAutoObservable(this);
  }

  setUser(user: User){
    this.user = user;
  }
  getUser(){
    return this.user;
  }
}


const data = new GlobalData();

const useGlobalData = () => {
  return data;
}

export type {User};
export default useGlobalData;